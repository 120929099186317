import Contact from "../Components/LandingPage/Contact";
import { Helmet } from "react-helmet";

export default function LandingPage() {
  return (
    <div>
      <Helmet>
        <title>V-art Gallery | Contact</title>
      </Helmet>
      <Contact />
    </div>
  );
}
