import { t } from "i18next";
import React from "react";
import { Link } from "react-router-dom";

export default function Gallery() {
  return (
    <div>
      <div className="w-full text-2xl ">
        <iframe
          title="gallery"
          className="w-full h-full "
          frameborder="0"
          src="https://lagalerie-vr.github.io/unseenVR/"
          allowfullscreen="true"
          mozallowfullscreen="true"
          webkitallowfullscreen="true"
          allowvr="true"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; vr"
        ></iframe>
      </div>
    </div>
  );
}
