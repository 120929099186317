import Faq from "../Components/LandingPage/Faq";
import { Helmet } from "react-helmet";

export default function LandingPage() {
  return (
    <div className="text-justify	">
      <Helmet>
        <title>V-art Gallery | FAQ</title>
      </Helmet>
      <Faq />
    </div>
  );
}
