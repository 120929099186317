import AGlimmerOfHope_SoloExhibition from "../Assets/exhibitions/AGlimmerOfHope_SoloExhibition.webp";
import TwistedBodies_SoloExhibition from "../Assets/exhibitions/TwistedBodies_SoloExhibition.webp";
import A000000000001000AA011_DuoExhibition from "../Assets/exhibitions/A000000000001000AA011_DuoExhibition.webp";

export const exhibitonsCurr = [
    {
        name: "Twisted Bodies | Solo Exhibition",
        date: "02/04/2024",
        imageUrl: TwistedBodies_SoloExhibition,
        link: "/TwistedBodies",
      },    {
        name: "A000000000001000AA011 | Duo Exhibition",
        date: "02/04/2024",
        imageUrl: A000000000001000AA011_DuoExhibition,
        link: "/A000000000001000AA011",
      },    {
        name: "A Glimmer Of Hope | Solo Exhibition",
        date: "02/04/2024",
        imageUrl: AGlimmerOfHope_SoloExhibition,
        link: "/AGlimmerOfHope",
      },
    
];
