/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export default function Slideover({ open, setOpen, selected }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-hidden  "
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="bg-black/60 w-full h-full backdrop-blur-sm"></div>
        </Transition.Child>
        <div className="absolute inset-0 overflow-hidden ">
          <Dialog.Overlay className="absolute inset-0 " />

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen  max-w-md">
                <div className="h-full  flex flex-col bg-white shadow-xl overflow-y-scroll">
                  <div className="px-4 py-6  sm:px-6">
                    <div className="flex items-start justify-between">
                      <h2
                        id="slide-over-heading"
                        className="text-lg font-medium text-gray-900"
                      >
                        Artist Details
                      </h2>
                      <div className="ml-3 h-7 flex items-center">
                        <button
                          type="button"
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>{" "}
                          <p className="font-bold">X</p>{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Main */}
                  <div>
                    <div className="pb-1 sm:pb-6">
                      <div>
                        <div className="relative h-40 sm:h-56">
                          <img
                            className="absolute h-full w-full object-cover"
                            src={selected.imageUrl}
                            alt=""
                          />
                        </div>
                        <div className="mt-6 px-4 sm:mt-8 sm:flex sm:items-end sm:px-6">
                          <div className="sm:flex-1">
                            <div>
                              <div className="flex items-center">
                                <h3 className="font-bold text-xl text-gray-900 sm:text-2xl">
                                  {selected.name}{" "}
                                </h3>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="px-4 pt-5 pb-5 sm:px-0 sm:pt-0">
                      <dl className="space-y-8 px-4 sm:px-6 sm:space-y-6">
                        <div>
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                            Bio
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                            <p>{selected.description}</p>
                          </dd>
                        </div>
                        <div>
                          <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">
                            Location
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                            {selected.location}
                          </dd>
                        </div>
                      </dl>
                    </div>
                    <div className="pb-8 flex px-4 flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">
                      {selected.instagram ? (
                        <a
                          href={selected.instagram}
                          target="_blank"
                          rel="noreferrer"
                          className="flex-shrink-0  w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-none shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:flex-1"
                        >
                          Instagram
                        </a>
                      ) : (
                        <p></p>
                      )}

                      {selected.instagram2 ? (
                        <a
                          href={selected.instagram2}
                          target="_blank"
                          rel="noreferrer"
                          className="flex-shrink-0  w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-none shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:flex-1"
                        >
                          Instagram
                        </a>
                      ) : (
                        <p></p>
                      )}
</div>
<div className="pb-8 flex px-4 flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">

                      {selected.exhibition ? (
                        <a
                          href={selected.exhibition}
                          target="_blank"
                          rel="noreferrer"
                          className="flex-shrink-0  w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-none shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:flex-1"
                        >
                          Visit Exhibition
                        </a>
                      ) : (
                        <p></p>
                      )}

                      {selected.facebook ? (
                        <a
                          href={selected.facebook}
                          target="_blank"
                          rel="noreferrer"
                          className="flex-shrink-0  w-full inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-none shadow-sm text-sm font-medium text-white bg-black hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 sm:flex-1"
                        >
                          Facebook
                        </a>
                      ) : (
                        <p></p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
