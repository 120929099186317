import PackForm from "../Components/LandingPage/PackForm";
import { Helmet } from "react-helmet";

export default function LandingPage() {
  return (
    <div>
      <Helmet>
        <title>V-art Gallery | Contact</title>
      </Helmet>
      <PackForm />
    </div>
  );
}
