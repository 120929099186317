import Artbox from "../Components/LandingPage/Artbox";
import { Helmet } from "react-helmet";

export default function LandingPage() {
  return (
    <div className="text-justify	">
      <Helmet>
        <title>V-art Gallery | Artbox</title>
      </Helmet>
      <Artbox />
    </div>
  );
}
