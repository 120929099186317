import React from "react";
import Sar from "../Components/3D/Replica";
import { Helmet } from "react-helmet";

export default function Artists() {
  return (
    <div>
      <Helmet>
        <title>V-art Gallery | Replica</title>
      </Helmet>
      <Sar />
    </div>
  );
}
