import Replica from "../Components/LandingPage/Replica";
import { Helmet } from "react-helmet";

export default function LandingPage() {
  return (
    <div className="text-justify	">
      <Helmet>
        <title>V-art Gallery | Replica</title>
      </Helmet>
      <Replica />
    </div>
  );
}
