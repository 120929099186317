import React from "react";
import { useTranslation } from "react-i18next";
import video from "../../Assets/videoBG.webm";

function Faq() {
  const { t } = useTranslation();

  return (
    <div className="relative bg-white p-8">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
          <video
            className="lg:h-full  pr-0 sm:pr-5 lg:w-full object-cover"
            src={video}
            alt="People working on laptops"
            autoPlay
            loop
            muted
          />
        </div>
      </div>

      <div className="relative pt-8 pb-2 px-4 sm:pt-6 sm:px-3 lg:px-3  lg:mx-auto lg:grid lg:grid-cols-2">
        <div className="lg:col-start-2 lg:pl-8">
          <h3 className="mt-2 text-3xl leading-8 pb-5 font-extrabold tracking-tight text-zinc-900 sm:text-4xl">
            {t("faq_text")}{" "}
          </h3>
          <div>
            {/* ---------- */}
            <div
              tabIndex={0}
              className="collapse my-5 collapse-arrow border border-base-300 bg-gray-50 "
            >
              <div className="collapse-title px-8">
                <h2 className=" tracking-tight text-gray-900">
                  <div dangerouslySetInnerHTML={{ __html: t("faq1") }} />
                </h2>{" "}
              </div>
              <div className="collapse-content px-8">
                <p className="mt-3  leading-6 text-gray-900">
                  <div dangerouslySetInnerHTML={{ __html: t("faq2") }} />
                </p>
              </div>
            </div>
            {/* ---------- */}

            {/* ---------- */}
            <div
              tabIndex={0}
              className="collapse my-5 collapse-arrow border border-base-300 bg-gray-50 "
            >
              <div className="collapse-title px-8">
                <h2 className=" tracking-tight text-gray-900">
                  <div dangerouslySetInnerHTML={{ __html: t("faq3") }} />
                </h2>{" "}
              </div>
              <div className="collapse-content px-8">
                <p className="mt-3  leading-6 text-gray-900">
                  <div dangerouslySetInnerHTML={{ __html: t("faq4") }} />
                </p>
              </div>
            </div>
            {/* ---------- */}

            {/* ---------- */}
            <div
              tabIndex={0}
              className="collapse my-5 collapse-arrow border border-base-300 bg-gray-50 "
            >
              <div className="collapse-title px-8">
                <h2 className=" tracking-tight text-gray-900">
                  <div dangerouslySetInnerHTML={{ __html: t("faq5") }} />
                </h2>{" "}
              </div>
              <div className="collapse-content px-8">
                <p className="mt-3  leading-6 text-gray-900">
                  <div dangerouslySetInnerHTML={{ __html: t("faq6") }} />
                </p>
              </div>
            </div>
            {/* ---------- */}

            {/* ---------- */}
            <div
              tabIndex={0}
              className="collapse my-5 collapse-arrow border border-base-300 bg-gray-50 "
            >
              <div className="collapse-title px-8">
                <h2 className=" tracking-tight text-gray-900">
                  <div dangerouslySetInnerHTML={{ __html: t("faq7") }} />
                </h2>{" "}
              </div>
              <div className="collapse-content px-8">
                <p className="mt-3  leading-6 text-gray-900">
                  <div dangerouslySetInnerHTML={{ __html: t("faq8") }} />
                </p>
              </div>
            </div>
            {/* ---------- */}

            {/* ---------- */}
            <div
              tabIndex={0}
              className="collapse my-5 collapse-arrow border border-base-300 bg-gray-50 "
            >
              <div className="collapse-title px-8">
                <h2 className=" tracking-tight text-gray-900">
                  <div dangerouslySetInnerHTML={{ __html: t("faq9") }} />
                </h2>{" "}
              </div>
              <div className="collapse-content px-8">
                <p className="mt-3  leading-6 text-gray-900">
                  <div dangerouslySetInnerHTML={{ __html: t("faq10") }} />
                </p>
              </div>
            </div>
            {/* ---------- */}

            {/* ---------- */}
            <div
              tabIndex={0}
              className="collapse my-5 collapse-arrow border border-base-300 bg-gray-50 "
            >
              <div className="collapse-title px-8">
                <h2 className=" tracking-tight text-gray-900">
                  <div dangerouslySetInnerHTML={{ __html: t("faq11") }} />
                </h2>{" "}
              </div>
              <div className="collapse-content px-8">
                <p className="mt-3  leading-6 text-gray-900">
                  <div dangerouslySetInnerHTML={{ __html: t("faq12") }} />
                </p>
              </div>
            </div>
            {/* ---------- */}

            {/* ---------- */}
            <div
              tabIndex={0}
              className="collapse my-5 collapse-arrow border border-base-300 bg-gray-50 "
            >
              <div className="collapse-title px-8">
                <h2 className=" tracking-tight text-gray-900">
                  <div dangerouslySetInnerHTML={{ __html: t("faq13") }} />
                </h2>{" "}
              </div>
              <div className="collapse-content px-8">
                <p className="mt-3  leading-6 text-gray-900">
                  <div dangerouslySetInnerHTML={{ __html: t("faq14") }} />
                </p>
              </div>
            </div>
            {/* ---------- */}

            {/* ---------- */}
            <div
              tabIndex={0}
              className="collapse my-5 collapse-arrow border border-base-300 bg-gray-50 "
            >
              <div className="collapse-title px-8">
                <h2 className=" tracking-tight text-gray-900">
                  <div dangerouslySetInnerHTML={{ __html: t("faq15") }} />
                </h2>{" "}
              </div>
              <div className="collapse-content px-8">
                <p className="mt-3  leading-6 text-gray-900">
                  <div dangerouslySetInnerHTML={{ __html: t("faq16") }} />
                </p>
              </div>
            </div>
            {/* ---------- */}

            {/* ---------- */}
            <div
              tabIndex={0}
              className="collapse my-5 collapse-arrow border border-base-300 bg-gray-50 "
            >
              <div className="collapse-title px-8">
                <h2 className=" tracking-tight text-gray-900">
                  <div dangerouslySetInnerHTML={{ __html: t("faq17") }} />
                </h2>{" "}
              </div>
              <div className="collapse-content px-8">
                <p className="mt-3  leading-6 text-gray-900">
                  <div dangerouslySetInnerHTML={{ __html: t("faq18") }} />
                </p>
              </div>
            </div>
            {/* ---------- */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Faq;
