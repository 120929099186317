import Solo from "../Components/3D/Solo";
import { Helmet } from "react-helmet";

export default function LandingPage() {
  return (
    <div>
      <Helmet>
        <title>V-art Gallery | Virtual Solo Exhibition</title>
      </Helmet>
      <Solo />
    </div>
  );
}
