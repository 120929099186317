import Walid from "../Assets/Artists/walid.webp";
import Sara from "../Assets/Artists/sara.webp";
import Elaine from "../Assets/Artists/Elaine.webp";
import Emmanuel from "../Assets/Artists/Emmanuel.webp";
import Emna from "../Assets/Artists/Emna.webp";
import Fatima from "../Assets/Artists/Fatima.webp";
import HaniAmra from "../Assets/Artists/HaniAmra.webp";
import LewisYelena from "../Assets/Artists/LewisYelena.webp";
import Mohanty from "../Assets/Artists/Mohanty.webp";
import Delta from "../Assets/Artists/Delta.webp";
import Daouda from "../Assets/Artists/Daouda.webp";
import Saqr from "../Assets/Artists/Saqr.webp";
import Beata from "../Assets/Artists/Beata.webp";
import Brut from "../Assets/Artists/Brut.webp";
import Christina from "../Assets/Artists/Christina.webp";
import Marek from "../Assets/Artists/Marek.webp";
import Mrad from "../Assets/Artists/Mrad.webp";
import Van from "../Assets/Artists/Van.webp";
import Fabio from "../Assets/Artists/FabioAdani.webp";
import ChourouMehdi from "../Assets/Artists/ChourouMehdi.webp"
import RichmondYawAdjei from "../Assets/Artists/RichmondYawAdjei.webp"
import LiliaKatherina from "../Assets/Artists/LiliaKatherina.webp"

export const people = [
  {
    name: "Lilia Li-Mi-Yan & Katherina Sadovsky",
    location: "Russia",
    imageUrl: LiliaKatherina,
    description:
"Lilia Li-Mi-Yan & Katherina Sadovsky, a dynamic duo of Russian artists, born in 1971 and 1985 respectively. Currently residing in Yerevan, Armenia, they embarked on their artistic journey after studying photography at the Rodchenko Art School in Moscow. Collaborating since 2016, their artistic repertoire spans across various mediums including photography, painting, sculpture, photo books, installation, video, and sound. They are also actively engaged in social activism, particularly in combating plastic waste in Moscow through interventions in public and natural spaces.",
instagram: "https://www.instagram.com/limiyan_lilia/",
instagram2:"https://www.instagram.com/sadovskykat/",
exhibition:"https://lagalerievr.com/A000000000001000AA011",
facebook: "",
  },


  {
    name: "Richmond Yaw Adjei",
    location: "Ghana",
    imageUrl: RichmondYawAdjei,
    description:
"Richmond Yaw Adjei, born in 1997, a contemporary artist celebrated for his captivating portraits meticulously crafted from newsprint. Based in Accra, Ghana, Yaw's artistic journey is deeply rooted in his childhood passion for craft and recycled art. Educated in Accra and Takoradi Technical University, Yaw holds a Higher National Diploma in Industrial Art and Design. Drawn to the informative and archival nature of newsprints, which now serves as his sole medium, his work reflects the beauty, horror and complexity of society. Inspired by his surroundings and childhood experiences, Yaw's art has gained recognition both locally and internationally. His pieces have been showcased in exhibitions across Ghana and the USA.",
    instagram: "https://www.instagram.com/yawadjei.newsprintart/",
    exhibition:"https://lagalerievr.com/AGlimmerOfHope",
    facebook: "",
  },

  {
    name: "Mohamed Samy Sakr",
    location: "Egypt",
    imageUrl: Saqr,
    description:
      "Mohamed Samy Sakr, born in 2002, is a self-taught Egyptian visual artist who has honed his skills through various workshops and courses. With a passion for expressing cultures, social and political issues, his artworks don't take a specific form, but rather embrace diversity and difference. Mohamed's art often explores the cryptic and mysterious, with a focus on the unseen world. He has a particular interest in African culture and masks, which are believed to contain spirits that bring prosperity and protect the tribe. Through his work, Mohamed seeks to express the richness and complexity of African culture, and to uncover the secrets and stories that lie beneath the surface.",
    instagram: "https://www.instagram.com/msaqr.art/",
    exhibition:"https://lagalerievr.com/TwistedBodies",
  },

  {
    name: "Mehdi Chourou",
    location: "Tunisia",
    imageUrl: ChourouMehdi,
    description:
"Semiotician, Visual Artist and Communication Expert, Mehdi works with Painting, Photography and Video Art. He presented his work in Finland, France, Germany and Tunisia. In 2009, he took part to Bauhaus 9090 exhibition organized by the Borderbend Arts Collective and the Gropius in Chicago Coalition. Mehdi is interested in the study of Sign processing, the construction and reception of meaning. MC’s Private Art Collectors are based in : USA, Canada, Switzerland, UK, Italy, Lebanon, Tunisia, Spain, Finland and France.",
    instagram: " https://www.instagram.com/nights.in.tunisia",
    facebook: "",
  },
  
  {
    name: "Fabio Adani",
    location: "Italy",
    imageUrl: Fabio,
    description:
      "Fabio Adani graduated in painting at the Academy of Fine Arts in Bologna. His artistic journey is a quest for rarefied beauty, a silent and mystical exploration that seeks to transcend the tangible. His work focuses on the ethereal, using light and material dilution to convey ideas and concepts before their concrete realization. It's a form of neo-metaphysical art that doesn't describe but evokes, inviting introspection and a search within oneself.",
    instagram: " https://instagram.com/fabioadani?igshid=MzRlODBiNWFlZA==",
    facebook: "",
  },

  {
    name: "Sara Alves",
    location: "Portugal",
    imageUrl: Sara,
    description:
      "Sara Alves, a young Portuguese artist born in Lisbon in 2000. With a background in Visual Arts and Graphic Design, Sara is currently completing her Painting Bachelor at the Faculty of Fine Arts of the University of Lisbon. Her oeuvre features Figurative Art and Surrealism, but goes beyond that, celebrating the complexity of human beings spiritually, psychologically, and socially. Despite her youth, Sara has exhibited her work in Portugal, Italy, and England and had her first solo show in 2022. ",
    instagram:
      "https://www.instagram.com/saraisdrawing/?fbclid=IwAR2lqm73tS091x_rviwZBVX2Al_pQY3BCY0Tq4L3IGVfD3kQyt3iiq9sqyQ",
    facebook: "",
  },

  {
    name: "Walid Ben Ghezala",
    location: "Tunisia",
    imageUrl: Walid,
    description:
      "Walid Ben Ghezala, a Tunisian artist born in 1991. He studied cinema and earned a degree in film editing at the Higher School of Architecture, Audiovisual and Design of Tunis. Walid's work as a photographer and videographer is characterized by a visual practice that combines documentary and poetic aesthetics. He is also a prose writer. In December 2019, he presented his first solo exhibition, Chambre(s) Noire(s), accompanied by the publication of his eponymous photo book at La Boîte, a contemporary art space in Tunis. His work has been selected in several festivals, including Foto Wien 2022, and Chambre 07 - International Photography Festival in Ardèche. He recently completed a residency at the Philomena+ gallery in Vienna, where he explored the experiences of migrant populations in the Viennese urban space. Based in Tunis, Walid is an emerging artist to watch.",
    instagram:
      "https://www.instagram.com/benghezalawalid/?fbclid=IwAR3x53skf12p7nqiVOM0awG__RzC6wTzP_D1Md00QfJnQZc46GLAU_bFSDU",
    facebook: "",
  },

  {
    name: "Elaine Crowe",
    location: "Ireland",
    imageUrl: Elaine,
    description:
      "Elaine Crowe, a Dublin lens-based artist born in 1971 whose work explores the relationship between the body and the landscape. With a Masters in Fine Art and a Degree in Sculpture and Expanded Practice, Crowe offers a unique gendered perspective on the landscape through her video, photography, and print works. Recent exhibitions include Mind Body Problem in China, Printmaking at the Edge in New Hampshire, and Impressions Print Biennale in Galway. By combining still and moving images, digital and analogue processes in multi-channel video and print installations, Crowe questions the gendered framing of the landscape and seeks to reframe it by unsettling the single, fixed perspective within the landscape's rectilinear frame. ",
    instagram:
      "https://www.instagram.com/elaine_crowe_artist/?fbclid=IwAR1v_vCrD67Q2uOX8vv90OPWF1K3Xa0odXpxNTQxiqAJpO0nFohs4AvCbzQ",
    facebook: "",
  },

  {
    name: "Yelena Lewis",
    location: "France",
    imageUrl: LewisYelena,
    description:
      "Yelena Lewis, a visual artist based in France who captures the diversity of human experience and the universality of emotions in her figurative style. With oil on canvas as her primary medium, she also works with ink, tempera, engraving, sculpture, mixed media, and acrylics. Yelena's playful yet tragic profiles convey opposing emotions such as joy and misery, happiness and sadness, bliss and grief, tranquility and anger. Her work has been exhibited in Switzerland, France, the UK, and Italy. Through her art, Yelena preserves life's diversity and interconnectivity, offering an alternative perspective on ourselves and the world around us. Join us in exploring her inner world.",
    instagram: "https://www.instagram.com/lewisalyona",
    facebook: "",
  },

  {
    name: "Emna Kahouaji",
    location: "Tunisia",
    imageUrl: Emna,
    description:
      "Emna Kahouaji, a Tunisian-based artist born in 1991 in Kairouan. Inspired by her father's poetry, Emna's work is a visual conversation between word, thought, and symbols, bridging the gap between painting and literature. Her unique approach to art is a reflection of her inner world and surrealist visions, often drawing from her dreams to create a universe from fragmented thoughts and feelings. After completing her Bachelor of Fine Arts in Painting at the Tunis Institute of Fine Arts in 2014, Emna went on to pursue a Masters in Traditional Heritage at the Faculty of Human and Social Sciences. Since then, she has participated in various exhibitions and symposiums held in Tunisia, showcasing her work and passion for art, Including a first solo exhibition 'AZAL' (Eternity) at the Galerie AIRE LIBRE in El Teatro in Tunis, 2019.",
    instagram: "https://www.instagram.com/emna_kahouaji",
    facebook: "",
  },

  {
    name: "Bibhu Ranjan Mohanty",
    location: "India",
    imageUrl: Mohanty,
    description:
      "Bibhu Ranjan Mohanty a contemporary artist who loves experimenting with different mediums, including watercolor, acrylic, oil, ink, charcoal, and mixed media. A trained classical dancer and singer, Bibhu is currently pursuing his Bachelor of Fine Arts at Amity University Haryana. His work is often inspired by nature and the world around him, and his themes center around his personal journey and the objects that surround him. He uses his unique perspective to create dreamy, fairy tale-like stories through his artwork, with a focus on self-portraits, lines, forms, shapes, textures, and colors. His recent exhibitions explore the concept of the unseen and the subconscious emotions that drive creativity. With his aesthetically pleasing work, Bibhu invites viewers to see the world through his eyes and experience the beauty of the unknown.",
    instagram: "https://www.instagram.com/bibhuuuu_/",
    facebook: "",
  },

  {
    name: "Hani Amra",
    location: "Palestine",
    imageUrl: HaniAmra,
    description:
      "Hani Amra, a Palestinian artist whose journey started with photography at age 14. After obtaining a grant to study arts in France, Hani's art evolved to painting, allowing him to expand his creativity. Today, he resides in Jerusalem, creating art that exhibits his relentless quest for freedom. In his latest work, Hani introduces Sufi concepts to decode his creative process, using everyday construction materials on stretched canvas. One of these concepts is the idea of gradual abstraction, Tajrid in Arabic. As parts of the original construction are carved away or extracted from the canvas, with a chisel and a hammer, something arises. The same occurs inside the artist's mind, until it becomes free from concepts and representations.",
    instagram: "https://www.instagram.com/hani_amra/",
    facebook: "",
  },

  {
    name: "Fatima Zahra Benhamza",
    location: "Morocco",
    imageUrl: Fatima,
    description:
      "Fatima Zahra Benhamza, a Moroccan architect and visual artist based in Casablanca. Through her architectural drawing, illustration, and collage, she questions and reimagines the contemporary urban issues of her city. Benhamza has participated in various artistic residencies in Morocco and abroad, including the Andreas Zust Bibliothek in Switzerland and the Albalad residency in Saudi Arabia. Her work has been exhibited internationally, including at the Liverpool Arab Arts Festival in 2021 and in solo and group shows in Tunisia, Germany, and Morocco.",
    instagram: "https://www.instagram.com/offset.jpg/",
    facebook: "",
  },

  {
    name: "Emmanuel Rodríguez Mazón",
    location: "Mexico",
    imageUrl: Emmanuel,
    description:
      "Emmanuel Rodríguez Mazón, a Mexican artist born in 1988 who graduated from the Faculty of Architecture, Mexico and Escola Massana, Spain. His art practice focuses on analyzing the relationship with space and matter through memory, combining media such as drawing, painting, photography, installation, urban intervention, performance, and sound art, to develop a transversal process that addresses the phenomenon of being spatially from the corporeal, memorial, affective, and phenomenal. His 'anarchitectural' vision aims to build a metaphorical language that relates the conceptual, plastic, and aesthetic solutions of his artwork with the fundamental concepts of architecture: stacking, covering, containing, and delimiting. Emmanuel has received awards and distinctions and his works have been exhibited in Mexico, Germany, Brazil, Ecuador, Spain, the United States, Italy, Greece, Nigeria, and Portugal. Through his art, Emmanuel tackles geopolitical objects and barriers as means of dissolving physical boundaries. Follow him to explore his unique vision of space and matter! ",
    instagram: "https://www.instagram.com/zaelvonmazon/",
    facebook: "",
  },

  {
    name: "Delta N.A.",
    location: "Italy",
    imageUrl: Delta,
    description:
      "Delta N.A. are a couple who work in unison, pouring their shared creative flow into each artwork. Their common language results in each painting appearing as if created by a single artist. Through love and collaboration, they embrace differences and break down the boundaries separating human nature from freedom. Their artworks are held in numerous public and private collections and have been exhibited worldwide. Their oil paintings take the viewer on a journey through the unseen parts of the human soul. Through the lens of a traveler, Delta N.A. explores exotic landscapes where dream and reality coexist. Their style seamlessly combines abstract and figurative elements, resulting in perfect harmony. ",
    instagram: "https://www.instagram.com/delta_na/",
    facebook: "",
  },

  {
    name: "Daouda Traore ",
    location: "Mali",
    imageUrl: Daouda,
    description:
      "Daouda Traore is a visual artist born in Abidjan, Côte d'Ivoire in 1987. He studied Fine Arts at the National Institute of Arts in Bamako, Mali and graduated top of his class in 2013 from the Conservatory of Arts and Multimedia Balla Fasséké Kouyaté. As a high school art teacher in Sikasso, Mali, he draws inspiration from the daily life and identities of his students to create his own unique universe. He describes his work as 'unreadable poetry' boldly expressing his spontaneity and individuality beyond imposed aesthetics. He uses a variety of materials, such as textiles, used metal sheets, tin cans, and millet sacks, to transform them with his own techniques. His works present everyday characters and situations with unexpected textures and ochre tones reminiscent of the Sahel's laterite earth. He incorporates scattered letters into his works to convey the importance of education and knowledge transmission amidst the current chaos in Mali.",
    instagram: "https://www.instagram.com/daouda_traore_plasticien/",
    facebook: "",
  },



  {
    name: "Brut Carniollus",
    location: "Slovenia",
    imageUrl: Brut,
    description:
      "Brut Carniollus, is a visual artist and photographer based in Slovenia. His work, which includes photography-based digital collages and digital graphics, is known for its reductional, deconstructional, and minimalist approach and it is exhibited and awarded worldwide. He draws inspiration in the many expressions of the human condition. His work often centers on portraiture, urban landscapes, geometric and abstract art, which he masterfully mixes together in his digital collages. In his creative process, Brut often starts with a single photograph or line of code, and incorporates other imagery to create larger digital collages and from there, he may crop, convert, or incorporate other imagery to create a larger, more complex piece. Brut sees himself as a witness to the times in which we live, and it is his privilege to create visual experiences that invite viewers to see themselves and their stories in his work.",
    instagram: "https://www.instagram.com/carniollus/",
    facebook: "",
  },

  {
    name: "Beata Króliczak-Zajko",
    location: "Poland",
    imageUrl: Beata,
    description:
      "Beata Króliczak-Zajko, is a graduate of Nicolaus Copernicus University's Faculty of Fine Arts in Toruń, Poland. With a diploma in Drawing and Graphics, Beata’s Macro-format unique prints using her own technique have been presented at various individual and group exhibitions, both in Poland and abroad.  Beata's art is deeply rooted in the human experience, exploring themes such as pain, suffering, sexuality, eroticism, and corporeality. She believes that the physicality of the human form is the best way to express our spirituality. Through her work, she invites us to confront the most unseen part of life – death, which we often conceal deeper than anything else.",
    instagram: "https://www.instagram.com/bea_znaki/",
    facebook: "",
  },

  {
    name: "Marek Zajko",
    location: "Poland",
    imageUrl: Marek,
    description:
      "Marek Zajko, a graphic artist born in Białystok, Poland. He graduated from the Nicolaus Copernicus University Faculty of Fine Arts in Toruń and currently works as a professor in the Department of Graphic Art. Marek creates intaglio graphics, drawings, and works using his own technique. His artworks have been presented in 80 individual exhibitions and over 250 international exhibitions and art presentations. Marek's cycle of graphic portraits captures the undiscovered depth of every human being, using typical graphic means of expression like exaggeration, transformation, and deformation to create a new image. ",
    instagram: "",
    facebook: "https://www.facebook.com/charles.bukowski.104",
  },

  {
    name: "Zohra Mrad",
    location: "Tunisia",
    imageUrl: Mrad,
    description:
      "Zohra Mrad aka mradzo, born in 1997, is a Tunisian visual artist and interaction designer based in Luxembourg. Her work invites a dialogue between the human experience and technology, exploring the intersections between open-source media and manual techniques like stop-motion, engraving, and painting. Zohra's focus on interactive installations and generative, textured and contrasted visuals challenges viewers to transform from passive observers to active creators. She collaborates with artists from diverse backgrounds to create immersive experiences that consider sustainability, inclusion, and movement. As a former student from ESAIG and The Gobelins School, and a member of the artists collectives B-Saad and Zolei, Zohra has exhibited her work in Europe and Tunisia. Her abstract and highly contrasted visuals, adapted to various mediums, are a reflection of her deep interest in the human sensory system, neuro-science, and traditional printing methods. Zohra is currently exploring how immersive performances and interactive installations can help us cope with the world around us. ",
    instagram: "https://www.instagram.com/mradzo/",
    facebook: "",
  },

  {
    name: "Christina Shiakola",
    location: "Cyprus",
    imageUrl: Christina,
    description:
      "Christina Shiakola born in 1977, is a Cypriot visual artist whose visual research explores everyday modern rituals through sculptures, installations, drawings, and prints. Her practice interprets paper size, text, and typography as a sculptural representation of space. She holds a Fine Art Degree from the University of Brighton, UK and an MFA from Frederick University, Cyprus. Her individual and group projects have been awarded and distinguished in several biennials and exhibitions in Cyprus, Greece, England, and North Macedonia including Larnaca Biennale 2018 and 2021 and the Osten Biennial of Drawing 2022. Shiakola is exploring the 'unseen' relationship between text, image, and context, highlighting the meaningful power of words and their potential to create an image beyond their communicative function.",
    instagram: "https://www.instagram.com/christina_shiakola/",
    facebook: "",
  },

  {
    name: "Van O",
    location: "Russia",
    imageUrl: Van,
    description:
      "Van O is a Moscow-born art photographer with a background in entomology, theater, and design. He creates striking and dynamic photographs by employing analog photography and wide film to capture movement and transformation of his subjects, even in seemingly static genres like still life. Van O incorporates multi-exposure and collaged images, including scientific and technical diagrams, to add depth and texture to his work. His pieces are in the collections of the Russian Museum of St. Petersburg, Erarta Museum of Contemporary Art, and various private collections. He is also a prize winner of several international contests, including the 'Heroes' contest - Marler Kunststern in 2017 and the 'From Selfie to Self-Expression' contest at Saatchi Gallery in 2017. Van O is also an advocate against the ongoing war, using his art to shed light on important issues.",
    instagram: "",
    facebook: "https://www.facebook.com/vano007",
  },
];
