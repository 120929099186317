import React from "react";
import Chourou from "../Components/3D/Chourou";
import { Helmet } from "react-helmet";

export default function Artists() {
  return (
    <div>
      <Helmet>
        <title>V-art Gallery | Infinity</title>
      </Helmet>
      <Chourou />
    </div>
  );
}
