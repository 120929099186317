import video from "../../Assets/videoBG.webm";
import { db } from "../../firebase-config";
import { collection, addDoc } from "firebase/firestore";
import { useState } from "react";
import ErrorModal from "../Modal/ErrorModal";
import ContactModal from "../Modal/ContactModal";
import { useTranslation } from "react-i18next";

export default function PackForm() {
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [pack, setPack] = useState("");
  const [text, setText] = useState("");

  const [confrimed, setconfrimed] = useState(false);
  const [error, setError] = useState(false);

  const quotaCollectionRef = collection(db, "quota");
  const createQuota = async () => {
    await addDoc(quotaCollectionRef, {
      lastName: lastName,
      name: name,
      phone: phone,
      text: text,
      pack: pack,
      email: email,
    });
  };

  const handleSubmit = async (e) => {
    if (
      name !== "" &&
      lastName !== "" &&
      pack !== "" &&
      phone !== "" &&
      email !== "" &&
      text !== ""
    ) {
      try {
        createQuota();
        setconfrimed(true);
      } catch (error) {
        setError(true);
      }
    } else {
      setError(true);
    }
  };

  const { t } = useTranslation();

  return (
    <div data-theme="lofi" className="relative bg-white">
      <div className="lg:absolute lg:inset-0">
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <video
            className="lg:h-full lg:w-full object-cover"
            src={video}
            alt="People working on laptops"
            autoPlay
            loop
            muted
          />
        </div>
      </div>
      <div className="relative py-16 px-4 sm:py-24 sm:px-6 lg:px-8  lg:mx-auto lg:py-16 lg:grid lg:grid-cols-2">
        <div className="lg:pr-8">
          <div>
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">
              {t("quota_title")}
            </h2>
            <p className="mt-4 text-lg text-gray-500 sm:mt-3">
              {t("quota_description")}
            </p>
            <form
              action="https://formsubmit.co/3b932283f3b40106984626b84d6be5a9"
              method="POST"
              className="mt-9 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            >
              <div>
                <div className="mt-1">
                  <input
                    type="text"
                    name="first-name"
                    id="first-name"
                    required
                    placeholder={t("first_name")}
                    autoComplete="given-name"
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                    className="block w-full border-b py-3 px-4 placeholder-gray-500 focus:ring-zinc-500 focus:border-zinc-500  border-zinc-900 rounded-none"
                  />
                </div>
              </div>
              <div>
                <div className="mt-1">
                  <input
                    type="text"
                    required
                    name="last-name"
                    id="last-name"
                    placeholder={t("last_name")}
                    onChange={(event) => {
                      setLastName(event.target.value);
                    }}
                    autoComplete="family-name"
                    className="block w-full border-b py-3 px-4 placeholder-gray-500 focus:ring-zinc-500 focus:border-zinc-500  border-zinc-900 rounded-none"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    required
                    placeholder={t("email")}
                    autoComplete="email"
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                    className="block w-full border-b py-3 px-4 placeholder-gray-500 focus:ring-zinc-500 focus:border-zinc-500  border-zinc-900 rounded-none"
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <div className="flex justify-between"></div>
                <div className="mt-1">
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    required
                    placeholder={t("phone")}
                    autoComplete="tel"
                    aria-describedby="phone-description"
                    onChange={(event) => {
                      setPhone(event.target.value);
                    }}
                    className="block w-full border-b py-3 px-4 placeholder-gray-500 focus:ring-zinc-500 focus:border-zinc-500  border-zinc-900 rounded-none"
                  />
                </div>
              </div>

              <fieldset className="sm:col-span-2">
                <legend className="block text-sm font-medium text-gray-700">
                  {t("pack_wanted")}
                </legend>
                <div className="mt-4 grid grid-cols-1 gap-y-4">
                  <div className="flex items-center">
                    <input
                      id="replica"
                      name="Group"
                      defaultValue="replica"
                      type="radio"
                      required
                      onChange={(event) => {
                        setPack(event.target.value);
                      }}
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label htmlFor="replica" className="ml-3">
                      <span className="block text-sm text-gray-700">
                        Replica{" "}
                      </span>
                    </label>
                  </div>

                  <div className="flex items-center">
                    <input
                      id="artbox"
                      name="Group"
                      defaultValue="artbox"
                      type="radio"
                      required
                      onChange={(event) => {
                        setPack(event.target.value);
                      }}
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label htmlFor="artbox" className="ml-3">
                      <span className="block text-sm text-gray-700">
                        Art Box{" "}
                      </span>
                    </label>
                  </div>

                  <div className="flex items-center">
                    <input
                      id="scan"
                      name="Group"
                      defaultValue="scan"
                      type="radio"
                      required
                      onChange={(event) => {
                        setPack(event.target.value);
                      }}
                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
                    />
                    <label htmlFor="scan" className="ml-3">
                      <span className="block text-sm text-gray-700">
                        3D scan{" "}
                      </span>
                    </label>
                  </div>
                </div>
              </fieldset>

              <div className="sm:col-span-2">
                <div className="flex justify-between"></div>
                <div className="mt-1">
                  <textarea
                    id="how-can-we-help"
                    name="how-can-we-help"
                    aria-describedby="how-can-we-help-description"
                    rows={4}
                    placeholder={t("message")}
                    className="block w-full border-b py-3 px-4 placeholder-gray-500 focus:ring-zinc-500 focus:border-zinc-500  border-zinc-900 rounded-none"
                    defaultValue={""}
                    required
                    onChange={(event) => {
                      setText(event.target.value);
                    }}
                  />
                </div>
              </div>

              <div className="text-right sm:col-span-2">
                <button
                  type="submit"
                  onClick={handleSubmit}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-none text-white bg-zinc-900 hover:bg-zinc-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {t("button_submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ContactModal open={confrimed} setOpen={setconfrimed} />
      <ErrorModal open={error} setOpen={setError} />
    </div>
  );
}
