import { useState } from "react";
import { db } from "../../firebase-config";
import { collection, addDoc } from "firebase/firestore";
import NewsletterModal from "../Modal/NewsletterModal";
import { useTranslation } from "react-i18next";

export default function Newsletter() {
  const emailsCollectionRef = collection(db, "newsletter");

  const createEmail = async () => {
    await addDoc(emailsCollectionRef, { email: input });
  };

  const [confrimed, setconfrimed] = useState(false);

  const [input, setInput] = useState("");

  const handleSubmit = async (e) => {
    if (input !== "") {
      try {
        createEmail();
        setconfrimed(true);
      } catch (error) {
        console.log(error);
      }
    }
  };
  const { t } = useTranslation();

  return (
    <div>
      <div className="flex gap-2	">
        <div>
          <input
            onChange={(event) => {
              setInput(event.target.value);
            }}
            type="email"
            name="email-address"
            id="email"
            autoComplete="email"
            required
            className="w-full bg-zinc-200 border border-transparent rounded-none py-2 px-4 flex items-center justify-center text-base font-medium text-zinc-900 hover:bg-zinc-300"
            placeholder={t("ns")}
          />
        </div>
        <div className="  ">
          <button
            onClick={handleSubmit}
            className=" bg-black border border-transparent rounded-none py-2 px-4 flex items-center justify-center text-base font-medium text-white hover:bg-zinc-800"
          >
            {t("ns_btn")}
          </button>
        </div>
      </div>
      <NewsletterModal open={confrimed} setOpen={setconfrimed} />
    </div>
  );
}
