import { Link } from "react-router-dom";
import img3 from "../../Assets/img3.webp";
import { useTranslation } from "react-i18next";

export default function Example() {
  const { t } = useTranslation();

  return (
    <div className="relative bg-white p-16 ">
      <div className="lg:absolute lg:inset-0 ">
        <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2 ">
          <img
            className="h-56 w-full object-cover lg:absolute lg:h-full"
            src={img3}
            alt=""
          />
        </div>
      </div>
      <div className="relative pt-8 pb-2 px-4 sm:pt-6 sm:px-3 lg:px-3 lg:max-w-7xl lg:mx-auto lg:grid lg:grid-cols-2 ">
        <div className="lg:col-start-2 lg:pl-8">
          <div className="text-base max-w-prose mx-auto lg:max-w-lg lg:ml-auto lg:mr-0">
            <h2 className="leading-6 text-gray-500 font-semibold tracking-wide uppercase">
              {t("our_services")}{" "}
            </h2>
            <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-zinc-900 sm:text-4xl">
              Replica{" "}
            </h3>
            <p className="mt-3 leading-6 text-gray-500">
              <div dangerouslySetInnerHTML={{ __html: t("replica_text") }} />
            </p>
            <div className="relative mx-auto  min-w-full my-8 ">
              <div className="relative mx-auto min-w-full">
                <div
                  tabIndex={0}
                  className="collapse collapse-arrow border border-base-300 bg-gray-50 "
                >
                  <input type="checkbox" />
                  <div className="collapse-title px-8">
                    <h2 className=" tracking-tight text-gray-900">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("replica_text2"),
                        }}
                      />
                    </h2>{" "}
                  </div>

                  <div className="collapse-content px-8">
                    <p className="mt-3  leading-6 text-gray-500">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("replica_text3"),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="relative mx-auto  min-w-full my-8 ">
              <div className="relative mx-auto min-w-full">
                <div
                  tabIndex={0}
                  className="collapse collapse-arrow border border-base-300 bg-gray-50 "
                >
                  <input type="checkbox" />
                  <div className="collapse-title px-8">
                    <h2 className=" tracking-tight text-gray-900">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("replica_text4"),
                        }}
                      />
                    </h2>{" "}
                  </div>

                  <div className="collapse-content px-8">
                    <p className="mt-3  leading-6 text-gray-500">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("replica_text5"),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>{" "}
            <div className="relative mx-auto  min-w-full my-8 ">
              <div className="relative mx-auto min-w-full">
                <div
                  tabIndex={0}
                  className="collapse collapse-arrow border border-base-300 bg-gray-50 "
                >
                  <input type="checkbox" />
                  <div className="collapse-title px-8">
                    <h2 className=" tracking-tight text-gray-900">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("replica_text6"),
                        }}
                      />
                    </h2>{" "}
                  </div>

                  <div className="collapse-content px-8">
                    <p className="mt-3  leading-6 text-gray-500">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("replica_text7"),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="relative mx-auto  min-w-full my-8 ">
              <div className="relative mx-auto min-w-full">
                <div
                  tabIndex={0}
                  className="collapse collapse-arrow border border-base-300 bg-gray-50 "
                >
                  <input type="checkbox" />
                  <div className="collapse-title px-8">
                    <h2 className=" tracking-tight text-gray-900">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("replica_text8"),
                        }}
                      />
                    </h2>{" "}
                  </div>

                  <div className="collapse-content px-8">
                    <p className="mt-3  leading-6 text-gray-500">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("replica_text9"),
                        }}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-5 prose prose-gray text-zinc-500">
              <br />
              <p>
                <Link
                  to="/form"
                  className="flex rounded-none items-center justify-center px-4 py-3  text-base font-medium  shadow-sm text-white bg-zinc-900 hover:bg-zinc-200 hover:text-zinc-900 sm:px-8"
                >
                  {t("quota_button")}
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
