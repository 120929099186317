import React from "react";
import TwistedBodies_SoloExhibition from "../Components/3D/TwistedBodies_SoloExhibition";
import { Helmet } from "react-helmet";

export default function Artists() {
  return (
    <div>
      <Helmet>
        <title>V-art Gallery | Twisted Bodies</title>
      </Helmet>
      <TwistedBodies_SoloExhibition />
    </div>
  );
}
