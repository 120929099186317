import Slideover from "../Modal/Slideover";
import { useState } from "react";
import { people } from "../../info/artists.js";
import { useTranslation } from "react-i18next";

export default function Example() {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState("");
  const { t } = useTranslation();

  return (
    <div className="bg-white">
      <div className="mx-auto py-12 px-16 min-w-full sm:px-6 lg:px-8 lg:py-8">
        <div className="space-y-5">
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-black">
              {t("exhibited_artists")}{" "}
            </h2>
            <p className="text-gray-500">{t("exhibited_text")} </p>
          </div>
          <ul
            role="list"
            className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-4 lg:gap-x-8 "
          >
            {people.map((person) => (
              <li key={person.name}>
                <div className=" border-2">
                  <button
                    onClick={(e) => {
                      setOpen(true);
                      setSelected(person);
                    }}
                  >
                    <div className="space-y-2">
                      <div className="aspect-w-4 aspect-h-2">
                        <img
                          className="object-cover shadow-lg rounded-none "
                          src={person.imageUrl}
                          alt=""
                        />
                      </div>

                      <div className="space-y-2">
                        <div className=" font-bold mb-1 ">
                          <h3 className="text-gray-900  font-bold ">
                            {person.name}
                          </h3>
                          <hr className="mx-12 my-2"></hr>
                          <p className="mb-2 text-gray-400 ">
                            {person.location}
                          </p>
                        </div>
                      </div>
                    </div>
                  </button>
                </div>
              </li>
            ))}
          </ul>
          <Slideover open={open} setOpen={setOpen} selected={selected} />
        </div>
      </div>
    </div>
  );
}
