import { Link } from "react-router-dom";
import img2 from "../../Assets/img2.webp";
import img3 from "../../Assets/img3.webp";
import img from "../../Assets/img.webp";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";

export default function Example() {
  const { t } = useTranslation();

  return (
    <>
      <div
        data-theme="lofi"
        className=" h-full min-w-full grid grid-rows-3 grid-cols-1 lg:grid-rows-1 lg:grid-cols-3"
      >
        <Link to="/replica" className="relative flex">
          <img
            src={img3}
            alt=""
            className="absolute inset-0 w-full h-full object-center object-cover"
          />
          <div className="relative w-full flex flex-col items-start justify-end bg-black bg-opacity-40 p-8 sm:p-12">
            <p className="mt-1 text-2xl font-medium text-white">
              {t("replica_title")}{" "}
            </p>
            <h2 className=" font-medium text-white text-opacity-75">
              {t("replica_hero_text")}
            </h2>
            <Link to="/replica">
              <button className="btn hover:bg-zinc-800 mt-4  uppercase">
                {t("services_button")}
              </button>
            </Link>
          </div>
        </Link>

        <Link className="relative flex" to="/artbox">
          <img
            src={img2}
            alt=""
            className="absolute inset-0 w-full h-full object-center object-cover"
          />

          <div className="relative w-full flex flex-col items-start justify-end bg-black bg-opacity-40 p-8 sm:p-12">
            <p className="mt-1 text-2xl font-medium text-white">
              {t("artbox_title")}{" "}
            </p>
            <h2 className=" font-medium text-white text-opacity-75">
              {t("artbox_hero_text")}
            </h2>
            <Link to="/artbox">
              <button className="btn hover:bg-zinc-800 mt-4 uppercase">
                {t("services_button")}
              </button>
            </Link>
          </div>
        </Link>

        <Link to="/scan" className="relative flex">
          <img
            src={img}
            alt=""
            className="absolute inset-0 w-full h-full object-center object-cover"
          />
          <div className="relative w-full flex flex-col items-start justify-end bg-black bg-opacity-40 p-8 sm:p-12">
            <p className="mt-1 text-2xl font-medium text-white">
              {" "}
              {t("scan_title")}{" "}
            </p>
            <h2 className=" font-medium text-white text-opacity-75">
              {t("scan_hero_text")}
            </h2>
            <Link to="/scan">
              <button className="btn hover:bg-zinc-800 mt-4 uppercase">
                {t("services_button")}
              </button>
            </Link>
          </div>
        </Link>

        <div className=" w-screen	">
          <Footer />
        </div>
      </div>
    </>
  );
}
