import React from "react";
import A000000000001000AA011_DuoExhibition from "../Components/3D/A000000000001000AA011_DuoExhibition";
import { Helmet } from "react-helmet";

export default function Artists() {
  return (
    <div>
      <Helmet>
        <title>V-art Gallery | A000000000001000AA011</title>
      </Helmet>
      <A000000000001000AA011_DuoExhibition />
    </div>
  );
}
