import React from "react";
import video from "../../Assets/videoBG.webm";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Newsletter from "./Newsletter";

export default function Hero() {
  const { t } = useTranslation();
  return (
    <div data-theme="lofi" className="bg-zinc-900 w-full h-auto">
      <div className="flex overflow-hidden relative w-full h-full mx-auto item-center justify-center ">
        <div className="absolute inset-0">
          <video
            className="h-full w-full object-cover"
            src={video}
            alt="People working on laptops"
            autoPlay
            loop
            muted
          />
          <div className="absolute inset-0  mix-blend-multiply" />
        </div>

        <div className="flex mt-12 items-stretch">
          <div className="hero-content flex-col text-center text-neutral-content">
            <div className="max-w-lg mb-2">
              <h1 className="mb-5 text-5xl font-bold uppercase">
                {t("hero_title")}
              </h1>
              <p className="mb-5">{t("hero_text")}</p>
              <Link to="/exhibitions">
                <button className="btn  uppercase hover:bg-zinc-800">
                  {t("hero_button")}{" "}
                </button>
              </Link>
            </div>
            <Newsletter />
          </div>
        </div>
      </div>
    </div>
  );
}
