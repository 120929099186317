import Unseen from "../Assets/exhibitions/unseen.webp";
import SoloExhibition from "../Assets/exhibitions/SoloExhibition.webp";
import Infinity from "../Assets/exhibitions/Infinity.webp";



export const exhibitons = [
  {
    name: "Infinity | Solo exhibition",
    date: "08/02/2024",
    imageUrl: Infinity,
    link: "/Infinity",
  },

  {
    name: "The Suspended Moment | Solo exhibition",
    date: "21/10/2023",
    imageUrl: SoloExhibition,
    link: "/TheSuspendedMoment",
  },
  {
    name: "UNSEEN | Virtual exhibition",
    date: "22/04/2023",
    imageUrl: Unseen,
    link: "/unseen",
  },  
];
