import { pastOpenCalls } from "../../info/pastOpenCalls";
import { currentOpenCalls } from "../../info/currentOpenCalls";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Example() {
  const { t } = useTranslation();

  return (
    <div className="bg-white">
      <div className="mx-auto py-12 px-16 min-w-full sm:px-6 lg:px-8 lg:py-8">
        <div className="space-y-5">
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-black">
              {t("current_openCalls")}{" "}
            </h2>
          </div>

          <ul
            role="list"
            className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-4 lg:gap-x-8 "
          >
            {currentOpenCalls.map((exhibiton) => (
              <li key={exhibiton.name}>
                <div className=" border-2">
                  <Link to={exhibiton.link}>
                    <button>
                      <div className="space-y-2">
                        <div className="aspect-w-4 aspect-h-2">
                          <img
                            className="object-cover shadow-lg rounded-none "
                            src={exhibiton.imageUrl}
                            alt=""
                          />
                        </div>

                        <div className="space-y-2">
                          <div className=" font-bold mb-1 ">
                            <h3 className="text-gray-900  font-bold ">
                              {exhibiton.name}
                            </h3>
                            <hr className="mx-12 my-2"></hr>
                            <p className="mb-2 text-gray-400 ">
                              {exhibiton.date}
                            </p>
                            <hr className="mx-12 my-2"></hr>
                            <button className="bg-black text-white px-6 py-2">
                              {t("services_button")}
                            </button>{" "}
                          </div>
                        </div>
                      </div>
                    </button>
                  </Link>
                </div>
              </li>
            ))}
          </ul>
          No Current Open calls, Stay Tuned
             
        </div>

        <div className="space-y-5 mt-6">
          <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
            <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl text-black">
              {t("past_openCalls")}{" "}
            </h2>
          </div>
          <ul
            role="list"
            className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:grid-cols-4 lg:gap-x-8 "
          >
            {pastOpenCalls.map((exhibiton) => (
              <li key={exhibiton.name}>
                <div className=" border-2">
                  <Link to={exhibiton.link}>
                    <button>
                      <div className="space-y-2">
                        <div className="aspect-w-4 aspect-h-2">
                          <img
                            className="object-cover shadow-lg rounded-none "
                            src={exhibiton.imageUrl}
                            alt=""
                          />
                        </div>

                        <div className="space-y-2">
                          <div className=" font-bold mb-1 ">
                            <h3 className="text-gray-900  font-bold ">
                              {exhibiton.name}
                            </h3>
                            <hr className="mx-12 my-2"></hr>
                            <p className="mb-2 text-gray-400 ">
                              {exhibiton.date}
                            </p>
                            <hr className="mx-12 my-2"></hr>
                            <button className="bg-black text-white px-6 py-2">
                              {t("services_button")}
                            </button>{" "}
                          </div>
                        </div>
                      </div>
                    </button>
                  </Link>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
