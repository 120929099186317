import React from "react";
import Fullpage, {
  FullPageSections,
  FullpageSection,
  FullpageNavigation,
} from "@ap.cx/react-fullpage";

import Hero from "../Components/LandingPage/Hero";
import Services from "../Components/LandingPage/Services";
import { Helmet } from "react-helmet";

export default function FullPageScroll() {
  const SectionStyle = {
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  return (
    <Fullpage>
      <Helmet>
        <title>V-art Gallery </title>
      </Helmet>
      <FullpageNavigation />
      <FullPageSections>
        <FullpageSection style={SectionStyle}>
          <Hero />
        </FullpageSection>
        <FullpageSection style={SectionStyle}>
          <Services />
        </FullpageSection>
      </FullPageSections>
    </Fullpage>
  );
}
