import Unseen from "../Assets/opencall.webp";
import artboxcall from "../Assets/artboxcall.webp";
import solo from "../Assets/opencall3.png";



export const pastOpenCalls = [
  {
    name: "Art Bridge | Virtual Solo Exhibition",
    date: "20/03/2024",
    imageUrl: solo,
    link: "/ArtBridge",
  },

    {
    name: "Artbox | Virtual Solo Exhibition",
    date: "31/08/2023",
    imageUrl: artboxcall,
    link: "/Artboxcall",
  },  
  {
    name: "UNSEEN | Virtual Exhibition",
    date: "31/03/2023",
    imageUrl: Unseen,
    link: "/UnseenCall",
  },
];
