import React from "react";
import artboxcall from "../../Assets/artboxcall.webp";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Call() {
  const { t } = useTranslation();

  return (
    <div data-theme="lofi">
      <p className="pt-6 max-w-lg mx-auto  text-black text-justify	text-xl sm:max-w-3xl  pl-10 pr-10 lg:pl-8 lg:pr-8">
        <img
          className=" content-center  mx-auto   pb-10 w-screen 	 sm:pb-10  "
          src={artboxcall}
          alt=""
        />

        <div dangerouslySetInnerHTML={{ __html: t("open_call_artbox") }} />
      </p>

      <div className="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
        <div className="space-y-4 flex flex-col sm:space-y-0 grid-cols-2 sm:mx-auto sm:inline-grid  sm:gap-5 pb-6">
          <a
            target="_blank"
            href="https://docs.google.com/forms/d/e/1FAIpQLScLjZrIHHllIB6ukNU9zEyiUgoGBHEj4I9rJf8yD9uD1Vtt8g/viewform"
            className=" rounded-none  px-4 py-3  text-base font-medium  shadow-sm btn  uppercase hover:bg-zinc-800 sm:px-8"
          >
            <button className="">{t("apply")}</button>
          </a>

          <Link
            to="/solo"
            className=" rounded-none  px-4 py-3  text-base font-medium  shadow-sm btn  uppercase hover:bg-zinc-800 sm:px-8"
          >
            <button className="">{t("solo")}</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Call;
